<template>
  <div class="main_container">
    <div class="row">
      <Notif :notif="notif" />
    </div>
    <div class="row mb-3 bread-crumbs">
      <div class="text-left col-12">
        <router-link :to="'/analyses/board'">
          Analyses >
        </router-link>
        <router-link :to="'/analyses/addBplan'">
          Business Plan >
        </router-link>
        <!-- <router-link :to="'/analyses/bplan'">
          
        </router-link> -->
        <span>Produits</span>
      </div>
    </div>
    
    <div class="row justify-content-center my-4">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title">
            Business plan
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-3">
        <a class="btn btn-blue"
           data-toggle="modal"
           ref="modal_button"
           data-target="#exampleModal">
          Créer un produit
        </a>
      </div>
    </div>
    <div class="row pt-3 mx-auto">
      <table class="table table-striped">
        <thead>
          <tr>
            <th class="th-blue"> 
              Libellé
            </th>

            <th class="th-blue">
              Date de mise en place
            </th>

            <!-- <th class="th-blue">
              Coût
            </th> -->
            <!-- <th class="th-blue">
              Croissance annuelle
            </th>
            <th class="th-blue">
              Croissance annuelle des charges
            </th>
            <th class="th-blue">
              Croissance annuelle des ressources
            </th> -->
            <th class="th-blue text-right">
              Taux d'actualisation des flux
            </th>
            <!-- <th class="th-blue">
              Taux d'inflation annuelle
            </th> -->
            <!-- <th class="th-blue">
              Nombre d'années
            </th> -->
            <th class="th-blue text-right">
              Fonds propre
            </th>
            <th class="th-blue">
              Actions
            </th>
          </tr>
        </thead>
        <tbody v-if="!loaded">
          <tr scope="row">
            <td colspan="11"
                class="text-center">Chargement en cours... </td>
          </tr>
        </tbody>
        <tbody v-else-if="loaded && long ==0">
          <tr scope="row">
            <td colspan="11"
                class="text-center">Aucune donnée enregistrée...</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr scope="row"
              v-for="(produit, prokey) in produits"
              :key="prokey">
            <td >{{ produit.libelle }}</td>
            <td >{{ produit.created_at }}</td>
            <!-- <td >{{ produit.cout }}</td> -->
            <!-- <td >{{ produit.croissance_annuelle }}</td>
            <td >{{ produit.croissance_annuelle_charge }}</td>
            <td >{{ produit.croissance_annuelle_ressource }}</td> -->
            <td class="text-right">{{(Math.round(produit.taux_actualisation_flux * 100) / 100).toLocaleString() }}</td>
            <!-- <td >{{ produit.taux_inflation_annuelle }}</td> -->
            <!-- <td >{{ produit.nombre_annee }}</td> -->
            <td class="text-right">{{ (Math.round(produit.fonds_propre * 100) / 100).toLocaleString() }}</td>
            <td class="text-right">
              <button class="btn btn-blue mr-1"
                      @click="launchListChargesRessources($route.params.id,produit.id)">+</button>
              <button type="button"
                      data-toggle="modal" 
                      data-target="#exampleModal" 
                      class="btn btn-blue mr-1"
                      @click="chargerProduit($route.params.id,produit.id)">
                <i class="flaticon-pencil"></i>
              </button>
              <!-- <button type="button"
                      data-toggle="modal" 
                      data-target="#exampleModal" 
                      class="btn btn-blue">
                <i class="flaticon-delete"></i>
              </button> -->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Modal -->
    <div class="modal fade"
         id="exampleModal"
         tabindex="-1"
         role="dialog"
         aria-labelledby="exampleModalLabel"
         aria-hidden="true">
      <div class="modal-dialog"
           role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title"
                id="exampleModalLabel">Créer un produit</h5>
            <button type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    ref="btn-close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for=""
                  >Libellé <sup><span class="req-star">*</span></sup></label>
                  <input type="text"
                         class="form-control"
                         v-model="form.libelle">
                </div>
                <div class="form-group col-md-6">
                  <label for=""
                  >Date de mise en place </label>
                  <input type="date"
                         class="form-control"
                         v-model="form.date_mise_en_place">
                </div>
              </div>
              
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for=""
                  >Taux d'actualisation des flux </label>
                  <input type="text"
                         class="form-control"
                         v-model="form.taux_actualisation_flux">
                </div>
                <div class="form-group col-md-6">
                  <label for=""
                  >Fonds propres</label>
                  <input type="text"
                         class="form-control"
                         @input="addSpace('fonds_propre')"
                         v-model="fonds_propre">
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                    @click="effacerForm()">Annuler</button>
            <button type="button"
                    class="btn btn-primary"
                    v-if="!modification"
                    @click="submit">créer</button>
            <button type="button"
                    class="btn btn-primary"
                    v-else
                    @click="modifierProduit()">Modifier</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
  .table tbody td,
  .table tfoot td,
  .table thead th,
  .form input.form-control,
  .form button.btn-info{
    font-size: 0.54em;
  }
  .table tbody tr{
    cursor: pointer;
  }

</style>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import form from "vuejs-form"
import Notif from "@/components/shared/Toast"
export default {
  name: "Produits",
  components: {
    Notif
  },
  data: () => ({
    notif: {
      activated: "",
      type: "",
      message: ""
    },
    produits:[],
    modification:false,
    loaded:false,
    long:0,
    fonds_propre:"",
    form: form({
      id:'',
      libelle: '',
      cout : '',
      date_mise_en_place :'',
      croissance_annuelle :'',
      croissance_annuelle_charge  :'',
      croissance_annuelle_ressource :'',
      taux_actualisation_flux :'',
      taux_inflation_annuelle :'',
      nombre_annee :'',
      fonds_propre :'',
      projet:''
    }).rules({
      libelle:"required",
      cout:"required",
    }),
  }),
  created() {
    this.form.projet=this.$route.params.id
    this.getListProduits(this.$route.params.id)
  },
  watch: {
    listProduits(){
      if (this.listProduits) {
        this.produits=this.listProduits.donnees
        this.produits.forEach(produit => {
          console.log(produit)
          produit.created_at=produit.created_at.split("T")[0]
        })
        this.long= this.produits.length
        this.loaded=true
      }
    },
    produit(){
      if (this.produit) {
        this.form.id = this.produit.id
        this.form.libelle = this.produit.libelle
        this.form.cout  = this.produit.cout
        this.form.date_mise_en_place  = this.produit.date_mise_en_place
        this.form.croissance_annuelle  = this.produit.croissance_annuelle
        this.form.croissance_annuelle_charge   = this.produit.croissance_annuelle_charge
        this.form.croissance_annuelle_ressource  = this.produit.croissance_annuelle_ressource
        this.form.taux_actualisation_flux  = (Math.round(this.produit.taux_actualisation_flux * 100) / 100)
        this.form.taux_inflation_annuelle  = this.produit.taux_inflation_annuelle
        this.form.nombre_annee  = this.produit.nombre_annee
        this.form.fonds_propre  = (Math.round(this.produit.fonds_propre * 100) / 100)
        this.fonds_propre = (Math.round(this.produit.fonds_propre * 100) / 100)
        this.addSpace('fonds_propre')
      }
    },
    failProduit(){
      if (this.failProduit) {
        this.notif.message = "Une erreur s'est produite."
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
          }.bind(this),
          2000
        )
      }
    },
    addProduit(){
      if (this.addProduit) {
        this.notif.message = "Le produit a été créer."
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.$refs["btn-close"].click()
            this.effacerForm()
            this.loaded=false
            this.getListProduits(this.$route.params.id)
          }.bind(this),
          2000
        )
      }
    },
    msgSuccessProduit(){
      if (this.msgSuccessProduit) {
        this.notif.message = "Le produit a été modifié."
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.$refs["btn-close"].click()
            this.effacerForm()
            this.loaded=false
            this.getListProduits(this.$route.params.id)
          }.bind(this),
          2000
        )
      }
    },
    failAddProduit(){
      if (this.failAddProduit) {
        this.notif.message = "Une erreur s'est produite."
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
          }.bind(this),
          2000
        )
      }
    },
    msgFailProduit(){
      if (this.msgFailProduit) {
        this.notif.message = "Une erreur s'est produite."
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
          }.bind(this),
          2000
        )
      }
    }
  },
  computed: {
    ...mapGetters(["listProduits","addProduit","produit","msgSuccessProduit","failListProduits","failAddProduit","failProduit","msgFailProduit"])
  },
  methods: {
    ...mapActions(["getListProduits","postProduit","getProduit","putProduit"]),
    ...mapMutations(["setListProduits","setAddProduit","setFailListProduits","setFailAddProduit","setFailProduit","setMsgFailProduit"]),
    launchListChargesRessources(projet,produit) {
      this.$router.push({ name: "ChargesRessources", params: { projet: projet, produit: produit } })
    },
    effacerForm(){
      this.form= form({
        id:'',
        libelle: '',
        cout : '',
        date_mise_en_place :'',
        croissance_annuelle :'',
        croissance_annuelle_charge  :'',
        croissance_annuelle_ressource :'',
        taux_actualisation_flux :'',
        taux_inflation_annuelle :'',
        nombre_annee :'',
        projet:'',
        fonds_propre :''
      }).rules({
        libelle:"required",
        cout:"required",
      })
    },
    submit(){
      // this.form.projet=this.$route.params.id
      this.postProduit(this.form.data)
    },
    modifierProduit(){
      // this.form.projet=this.$route.params.id
      this.putProduit(this.form.data)
    },
    chargerProduit(produit,id){
      this.modification=true
      this.getProduit({produit:produit,id:id})
    },
    addSpace(key) {
      var chaine = ""
      var splinter = this[key].split("")
      var res =""
      this.form[key]=""
      if (/[0-9]/.test(this[key]) || !/[A-Za-zéèàôêâîûöïüëä$&+,:;=?@#|"~{}'<>.-^*()%!]/.test(this[key])) {
        for (let index = 0; index < splinter.length; index++) {
          if (!/[0-9]/.test(splinter[index]) && !splinter[index] == " ") {
            splinter[index] = ""
          }
          chaine += splinter[index]
        }
        if (this[key].length >= 4) {
          // check if there's a space
          if (this[key].includes(" ")) {
            var trimer = chaine.split(" ")
            chaine = ""
            for (let index = 0; index < trimer.length; index++) {
              chaine += trimer[index]
            }
            chaine = chaine.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          } else {
            chaine = ""
            chaine = this[key].replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          }
        }
      }
      this[key] = chaine
      res = this[key].split(" ")
      for (let index = 0; index < res.length; index++) {
        this.form[key] += res[index]
      }
      this.form[key]=Number(this.form[key])
    },
  }
}
</script>
